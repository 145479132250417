<template>
  <div class="course_detail">
    <landing ref="landing"></landing>
    <div><img style="width: 100%;object-fit: contain;" :src="detail.coverImg" /></div>
    <van-tabs v-model="tabValue">
      <van-tab
        title="介绍"
        name="about"
      ></van-tab>
      <van-tab
        title="评论"
        name="about"
      ></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import landing from '../base/landing'
export default {
  data: () => ({
    detail: {},
    tabValue: 'about'
  }),
  components: {
    landing
  },
  mounted () {
  
    this.$api.course.getCustomerServiceInfo({ code: this.$route.query.code })
      .then(res => {
        if (res.code === 0) {
          this.getDetail(res.data)
          this.$api.course.getPayOrder({
            appid: this.$store.getters['user/getAppid'],
            openId: this.$store.getters['user/getOpenid'],
            contentId: res.data.id,
            appId: this.$store.getters['user/getAppid'],
            userId: ''
          }).then((res => {
            if (res.code === 0) {
              console.log('开始支付')
              this.$common.wxPay(res.data).then((res) => {
                console.log(res, 'res')
              })
            }
          }))
        }
      })
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    async getDetail ({ id }) {
      const res = await this.$api.course.detail({ id })
      if (!res.code) {
        this.detail = res.data
      }
    }
  }

}
</script>
